import { Menu, MenuItemLink, useResourceDefinitions} from 'react-admin';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';

const MyMenu = () => {
    const resources = useResourceDefinitions();
    return (
        <Menu>
            {Object.keys(resources).map(name => (
                (resources[name].hasList) && (<Menu.ResourceItem key={name} name={name} />)
            ))}
           <MenuItemLink to="/metrics" primaryText="Metrics" leftIcon={<StackedLineChartIcon/>}/>
        </Menu>
    );
};


export default MyMenu;