import React from "react";
import {
  Notification,
  useLogin,
  useTranslate,
} from "react-admin";
import {
  Avatar,
  Button,
  Card,
  CardActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import LockIcon from "@mui/icons-material/Lock";
import Keycloak from "keycloak-js";

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 1em)",
    alignItems: "center",
    justifyContent: "flex-start",
    background: "url(./images/floating-cogs.svg)",
    backgroundColor: "#f9f9f9",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: "30em",
    marginTop: "6em",
    marginBottom: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  serverVersion: {
    color: "#9e9e9e",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    marginBottom: "1em",
    marginLeft: "0.5em",
  },
}));

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID,
  realmAccess: process.env.REACT_APP_KEYCLOAK_ACCESS_ROLE,
});

keycloak.init({
  checkLoginIframe: false
}).then((authenticated) => {
  if (authenticated) {
    localStorage.setItem("keycloak_token", keycloak.token);
    localStorage.setItem("keycloak_refresh_token", keycloak.refreshToken);
    localStorage.setItem("sso_base_url", process.env.REACT_APP_SERVER);
    const ssoFullUrl = `${process.env.REACT_APP_SERVER}/_matrix/client/r0/login/sso/redirect?redirectUrl=${encodeURIComponent(
      window.location.href
    )}`;
    window.location.href = ssoFullUrl;
  }
});

setInterval(() => {
  if(localStorage.getItem("keycloak_token")){
    keycloak.refreshToken = localStorage.getItem("keycloak_refresh_token");
    keycloak.updateToken(30).then((updated) => {
      if(updated) {
        localStorage.setItem("keycloak_token", keycloak.token);
        localStorage.setItem("keycloak_refresh_token", keycloak.refreshToken);
      }
    },
      (error) => {
        console.error('failed to get a new auth token', error);
      }
    )
  }
}, 3000);

const LoginPage = ({ theme }) => {
  const classes = useStyles({ theme });
  const login = useLogin();
  const translate = useTranslate();
  const loginToken = /\?loginToken=([a-zA-Z0-9_-]+)/.exec(window.location.href);

  if (loginToken) {
    const ssoToken = loginToken[1];
    console.log("SSO token is", ssoToken);
    // Prevent further requests
    window.history.replaceState(
      {},
      "",
      window.location.href.replace(loginToken[0], "#").split("#")[0]
    );
    const baseUrl = localStorage.getItem("sso_base_url");
    localStorage.removeItem("sso_base_url");
    if (baseUrl) {
      const auth = {
        base_url: baseUrl,
        loginToken: ssoToken,
      };
      console.log("Base URL is:", baseUrl);
      console.log("SSO Token is:", ssoToken);
      console.log("Let's try token login...");
      login(auth).catch(error => {
        alert(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message
        );
        console.error(error);
      });
    }
  }

  return (
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Avatar className={classes.icon}>
                  <LockIcon />
                </Avatar>
              </div>
              <div className={classes.hint}>
                {translate("synapseadmin.auth.welcome")}
              </div>
              <br></br>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={keycloak.login}
                  disabled={process.env.REACT_APP_SERVER === ""}
                  className={classes.button}
                  fullWidth
                >
                  {translate("synapseadmin.auth.sso_sign_in")}
                </Button>
              </CardActions>
            </Card>
            <Notification />
          </div>
  );
};

export default LoginPage;
