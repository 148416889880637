import "../assets/style.css"
import React from "react";
import { cloneElement, useState, useEffect } from "react";
import {
  Datagrid,
  ExportButton,
  Filter,
  List,
  NumberField,
  Pagination,
  sanitizeListRestProps,
  SearchInput,
  Title,
  TextField,
  TopToolbar,
  useListContext,
} from "react-admin";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { DeleteMediaButton } from "./media";
import {Card, Switch, CardContent, Typography, FormControlLabel, Slider} from '@mui/material'
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const ListActions = props => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <DeleteMediaButton />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const UserMediaStatsPagination = props => (
  <Pagination {...props} rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} />
);

const UserMediaStatsFilter = props => (
  <Filter {...props}>
    <SearchInput source="search_term" alwaysOn />
  </Filter>
);

export const UserMediaStatsList = props => {
  return (
    <List
      {...props}
      actions={<ListActions />}
      filters={<UserMediaStatsFilter />}
      pagination={<UserMediaStatsPagination />}
      sort={{ field: "media_length", order: "DESC" }}
      bulkActionButtons={false}
    >
      <Datagrid rowClick={(id, basePath, record) => "/users/" + id + "/media"}>
        <TextField source="user_id" label="resources.users.fields.id" />
        <TextField
          source="displayname"
          label="resources.users.fields.displayname"
        />
        <NumberField source="media_count" />
        <NumberField source="media_length" />
      </Datagrid>
    </List>
  );
};

const FilteredMetrics = () => {
  const metrics = [198,75,136,32,182,184];

  return (
    <div className="gridcontainer">
      {
        metrics.map((id)=>{
          return(
          <div className="griditem">
            <iframe src={`http://localhost:3000/d-solo/000000012/synapse?orgId=1&from=${Date.now()-30*60*1000}&to=${Date.now()}&var-datasource=Prometheus&var-bucket_size=%24__auto_interval_bucket_size&var-instance=server.element.neo.ohp.ibmgcloud.net%3A443&var-job=All&var-index=All&panelId=${id}`} width="450" height="200" frameborder="0"></iframe>
          </div>)
        })
      }
    </div>
  )
}

const CustomMetrics = ({stats, metrics}) => {
  return (
    <div className="gridcontainer">
      {stats.length > 0 &&
        Object.keys(metrics).map((key)=>{
          return(
            <div className="griditem">
            <Card sx={{ maxWidth: 250 }}>
            <CardContent>
              <Typography variant="h3" component="div">
              {stats.at(-1)[key]}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {metrics[key]}
              </Typography>
            </CardContent>
          </Card>
          </div>)
        })
      }
    </div>
  )
}

const DetailedCustomMetrics = ({stats, metrics, days}) => {
  const today = new Date();
  const filteredMetrics = {};

  Object.keys(metrics).forEach((key)=> {
    filteredMetrics[key] = {};
  });

  //Split Metrics into 30 Days
  for (let i = 0; i < days; i++) {
    //Get the day for the current iteration
    const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i);
    const formattedDate = date.toISOString().split('T')[0];

    //Set metric to 0 for each metrics that we need
    Object.keys(metrics).forEach((key)=> {
      filteredMetrics[key][formattedDate] = 0;
    });
    
    let count = 0;
    
    //Loop through each stat and check to which day it belongs to and calculate average value of each metric we need
    stats.forEach((metric) => {
      const metricDate = new Date(metric.local_timestamp * 1000).toISOString().split('T')[0];
      if (metricDate === formattedDate) {
        Object.keys(metrics).forEach((key)=> {
          filteredMetrics[key][formattedDate] += metric[key];
        });
        count++;
      }
    });
    
    //Calculate the average of the day and round it
    Object.keys(metrics).forEach((key)=> {
      if(count >0){
        filteredMetrics[key][formattedDate] /= count;
        filteredMetrics[key][formattedDate] = Math.round(filteredMetrics[key][formattedDate]);
      }else{
        delete filteredMetrics[key][formattedDate];
      }
    });
  }

  return (
    <div className="gridcontainer">
      {stats.length > 0 &&
        Object.keys(metrics).map((key)=>{
          return(
            <div className="griditem">
            <Line
              width="450" 
              height="200" 
              data={{
                labels: Object.keys(filteredMetrics[key]).reverse(),
                datasets: [{
                  label: metrics[key],
                  data: Object.values(filteredMetrics[key]).reverse(),
                  fill: true,
                  borderColor: 'rgb(33, 150, 243)',
                  tension: 0.1
                }]
              }}
            />
          </div>)
        })
      }
    </div>
  )
}

export const ServerMetrics = (props) => {
  const [grafana, setGrafana] = useState(false); 
  const [filter, setFilter] = useState(false);
  const [stats, setStats] = useState([]);
  const [days, setDays] = useState(30);

  const metrics = {
    "total_users":"total users", 
    "total_room_count":"total rooms",
    "daily_active_users": "daily active users",
    "daily_user_type_native": "daily new created users",
    "daily_e2ee_messages":"daily messages",
    "daily_active_e2ee_rooms":"daily active rooms",
    "monthly_active_users":"monthly active users",
  };
  
  useEffect(() => {
    if (!grafana){
      fetch(process.env.REACT_APP_PANOPTICON_API + "/stats"
      , { headers: {
              'Authorization': 'Bearer ' + localStorage.getItem("keycloak_token")
          }
        })
        .then(response => {
          if(!response.ok) throw new Error("Failed to fetch stats, status: " + response.status)
          return response.json()
        })
        .then(data => {
          setStats(data);
        }).catch((error) => {
          console.error(error)
        })
    }
  }, [grafana])

  return(
  <div style={{height:"100%"}}>
    <Title defaultTitle="Metrics"></Title>
    <div style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    }}>
      {/*<div className="griditem">
        <FormControlLabel control={<Switch onChange={()=>{setGrafana(!grafana);}}/>} label="Grafana" />
      </div>*/}
      <div className="griditem" style={{width:"600px", marginTop: "10px"}}>
      {
        (filter && !grafana) &&
        <Slider
          aria-label="Days"
          value={days}
          step={30}
          marks={Array.from({length: 12}, (_, i) => ({value: (i+1)*30, label: `${(i+1)*30}d`}))}
          min={30}
          max={360}
          onChange={(event) => setDays(event.target.value)}
        />
      }
      </div>
      <div className="griditem">
        <FormControlLabel control={<Switch checked={filter} onChange={()=>{setFilter(!filter);}}/>} label={grafana ? "Filtered": "Detailed"} />
      </div>
    </div>
    {grafana ? (filter ? <FilteredMetrics/> : <iframe src="http://localhost:3000/d/000000012/synapse?orgId=1&from=now-15m&to=now" frameborder="0" height="100%" width="100%"></iframe>) : (filter ? <DetailedCustomMetrics stats={stats} metrics={metrics} days={days}/> : <CustomMetrics stats={stats} metrics={metrics}/>) }
  </div>
  );
}
