import React, { Fragment } from "react";
import Avatar from "@mui/material/Avatar";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import { makeStyles } from "@mui/styles";
import {
  BooleanField,
  BulkDeleteButton,
  Button,
  Datagrid,
  DeleteButton,
  List,
  NumberField,
  Pagination,
  TextField,
  useCreate,
  useNotify,
  useTranslate,
  useRefresh,
  useUnselectAll,
} from "react-admin";

import {useMutation} from "react-query";

const useStyles = makeStyles({
  small: {
    height: "40px",
    width: "40px",
  },
});

const RoomDirectoryPagination = props => (
  <Pagination {...props} rowsPerPageOptions={[100, 500, 1000, 2000]} />
);

export const RoomDirectoryDeleteButton = props => {
  const translate = useTranslate();

  return (
    <DeleteButton
      {...props}
      label="resources.room_directory.action.erase"
      redirect={false}
      mutationMode="pessimistic"
      confirmTitle={translate("resources.room_directory.action.title", {
        smart_count: 1,
      })}
      confirmContent={translate("resources.room_directory.action.content", {
        smart_count: 1,
      })}
      resource="room_directory"
      icon={<FolderSharedIcon />}
    />
  );
};

export const RoomDirectoryBulkDeleteButton = props => (
  <BulkDeleteButton
    {...props}
    label="resources.room_directory.action.erase"
    mutationMode="pessimistic"
    confirmTitle="resources.room_directory.action.title"
    confirmContent="resources.room_directory.action.content"
    resource="room_directory"
    icon={<FolderSharedIcon />}
  />
);

export const RoomDirectoryBulkSaveButton = ({ selectedIds }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll();
  const {createMany, isLoading } = useMutation();

  const handleSend = values => {
    createMany(
      {
        type: "createMany",
        resource: "room_directory",
        payload: { ids: selectedIds, data: {} },
      },
      {
        onSuccess: ({ data }) => {
          notify("resources.room_directory.action.send_success");
          unselectAll("rooms");
          refresh();
        },
        onFailure: error =>
          notify("resources.room_directory.action.send_failure", "error"),
      }
    );
  };

  return (
    <Button
      label="resources.room_directory.action.create"
      onClick={handleSend}
      disabled={isLoading}
    >
      <FolderSharedIcon />
    </Button>
  );
};

export const RoomDirectorySaveButton = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [create, { loading }] = useCreate("room_directory");

  const handleSend = values => {
    create(
      {
        payload: { data: { id: record.id } },
      },
      {
        onSuccess: ({ data }) => {
          notify("resources.room_directory.action.send_success");
          refresh();
        },
        onFailure: error =>
          notify("resources.room_directory.action.send_failure", "error"),
      }
    );
  };

  return (
    <Button
      label="resources.room_directory.action.create"
      onClick={handleSend}
      disabled={loading}
    >
      <FolderSharedIcon />
    </Button>
  );
};

const RoomDirectoryBulkActionButtons = props => (
  <Fragment>
    <RoomDirectoryBulkDeleteButton {...props} />
  </Fragment>
);

const AvatarField = ({ source, className, record = {} }) => (
  <Avatar src={record[source]} className={className} />
);

export const RoomDirectoryList = ({
  ...props
}) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <List
      {...props}
      pagination={<RoomDirectoryPagination />}
      perPage={100}
    >
      <Datagrid rowClick={(id, basePath, record) => "/rooms/" + id + "/show"} bulkActionButtons={<RoomDirectoryBulkActionButtons />}>
        <AvatarField
          source="avatar_src"
          sortable={false}
          className={classes.small}
          label={translate("resources.rooms.fields.avatar")}
        />
        <TextField
          source="name"
          sortable={false}
          label={translate("resources.rooms.fields.name")}
        />
        <NumberField
          source="num_joined_members"
          sortable={false}
          label={translate("resources.rooms.fields.joined_members")}
        />
        <BooleanField
          source="world_readable"
          sortable={false}
          label={translate("resources.room_directory.fields.world_readable")}
        />
        <BooleanField
          source="guest_can_join"
          sortable={false}
          label={translate("resources.room_directory.fields.guest_can_join")}
        />
      </Datagrid>
    </List>
  );
};
