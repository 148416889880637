import * as React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import authProvider from './synapse/authProvider';
import Box from '@mui/material/Box';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        transparency: "50"
    },
    spacer: {
        flex: 1,
    },
    header: {
        backgroundColor: "#003C76"
    },
});

const MyAppBar = props => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();

    const execLogout = () => {
      authProvider.logout().then((value) => {
        navigate("/login");
      });
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
        <AppBar className={classes.header}>
            <Typography
                variant="h6"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer} />
            <div>
            <Box sx={{ flexGrow: 0 }}>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={execLogout}>
                    <ListItemIcon>
                        <PowerSettingsNew />
                    </ListItemIcon>
                    <ListItemText primary="Logout"/></MenuItem>
              </Menu>
              </Box>
            </div>
        </AppBar>
    );
};

export default MyAppBar;