import React from "react";
import { Admin, CustomRoutes, Resource, resolveBrowserLocale } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import authProvider from "./synapse/authProvider";
import dataProvider from "./synapse/dataProvider";
import { UserList, UserCreate, UserEdit } from "./components/users";
import { RoomList, RoomShow } from "./components/rooms";
import { ReportList, ReportShow } from "./components/EventReports";
import LoginPage from "./components/LoginPage";
import UserIcon from "@mui/icons-material/Group";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { ServerMetrics, UserMediaStatsList } from "./components/statistics";
import RoomIcon from "@mui/icons-material/ViewList";
import ReportIcon from "@mui/icons-material/Warning";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import { ImportFeature } from "./components/ImportFeature";
import {
  RegistrationTokenCreate,
  RegistrationTokenEdit,
  RegistrationTokenList,
} from "./components/RegistrationTokens";
import { RoomDirectoryList } from "./components/RoomDirectory";
import { Route } from "react-router-dom";
import englishMessages from "./i18n/en";
import MyLayout from "./MyLayout";


const i18nProvider = polyglotI18nProvider(
  () => (englishMessages),
  resolveBrowserLocale()
);


const App = () => (
      <Admin
        disableTelemetry
        loginPage={LoginPage}
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        layout={MyLayout}
      > 
        <CustomRoutes>
          <Route path="/import_users" element={<ImportFeature />} />
          <Route path="/metrics" element={<ServerMetrics />} />
        </CustomRoutes>
        <Resource
          name="users"
          list={UserList}
          create={UserCreate}
          edit={UserEdit}
          icon={UserIcon}
        />
        <Resource name="rooms" list={RoomList} show={RoomShow} icon={RoomIcon} />
        <Resource
          name="user_media_statistics"
          list={UserMediaStatsList}
          icon={EqualizerIcon}
        />
        <Resource
          name="reports"
          list={ReportList}
          show={ReportShow}
          icon={ReportIcon}
        />
        <Resource
          name="room_directory"
          list={RoomDirectoryList}
          icon={FolderSharedIcon}
        />
        <Resource
          name="registration_tokens"
          list={RegistrationTokenList}
          create={RegistrationTokenCreate}
          edit={RegistrationTokenEdit}
          icon={ConfirmationNumberIcon}
        />
        <Resource name="connections" />
        <Resource name="devices" />
        <Resource name="room_members" />
        <Resource name="users_media" />
        <Resource name="joined_rooms" />
        <Resource name="pushers" />
        <Resource name="servernotices" />
        <Resource name="forward_extremities" />
        <Resource name="room_state" />
      </Admin>
);

export default App;
